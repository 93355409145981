import React, { useState } from "react"
import Section from "./Section";
import Image from "./Image";
import { useStaticQuery, graphql, Link } from "gatsby"

function BlogGrid(){
  const data = useStaticQuery(graphql`
    query Blog {
      allMarkdownRemark (sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            frontmatter {
              title
              slug
              date(formatString: "MMMM DD, YYYY")
              banner_image
              card_image
            }
          }
        }
      }
    }
  `)

  const { edges: blogs } = data.allMarkdownRemark;

  return (

    <div className="blogs__columns">
    {blogs.map((blog) => {
      return(
        
      <div className="blogs__column">
      <Link to={blog.node.frontmatter.slug}>
      <Image
        src={blog.node.frontmatter.card_image}
        alt={""}
      />

      <div className="column-content__text">
        <h3>{blog.node.frontmatter.title}</h3>

        <p>
        {blog.node.frontmatter.date}
        </p>
      </div>
      </Link>
    </div>
      )
    })}

    </div>  

      
  );
}

export default BlogGrid;