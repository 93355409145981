import React from "react";
import Layout from "../../components/Layout";
import Section from "../../components/Section"
import SectionBanner from "../../components/SectionBanner";
import SectionOverview from "../../components/SectionOverview"
import Seo from "../../components/Seo";
import BlogGrid from "../../components/BlogGrid";

export default function CareersLanding() {

  return (
    <Layout id="blog-landing">
      <Seo
        title="Blog and News"
        description="Explore the latest Bixal news and insights from our thought leaders."
        relPath={"/blog/"}
      />
      <div className="page--blog">

        <SectionBanner
          id="blog-hero"
          bgImage={"assets/images/blog-and-news-header.png"}
          bgTextShade={true}
          title="Blog and News"
        />


        <Section
          id="blog-intro"
          constrain
          indent
          spacing={80}
        >
          <SectionOverview divider>
            <h2>Explore the latest Bixal news and insights from our thought leaders.</h2>
          </SectionOverview>

          <BlogGrid />
        </Section>
       
        
  
      </div>
    </Layout>
  )
}
